import locales from '@humans-sdk/core/utils/locales';
import { yup } from '@humans-sdk/core/utils/validators';

import { LocaleNames } from '@constants';
import { Dictionary } from '@typings';

import translations from './validators.translations.json';

declare module 'yup' {
    export interface BasicArraySchema<E, T extends E[] | null | undefined> {
        oneOfArraysRequire(deps: [string, string], error?: string): BasicArraySchema<E, T>;
    }
}

type OneOfArraysRequireContextType = {
    path: string;
    parent: Dictionary<unknown>;
};

const localeName = LocaleNames.VALIDATORS;
locales.addResourceBundle('en', localeName, translations);

yup.addMethod<yup.BasicArraySchema<string, string[]>>(
    yup.array,
    'oneOfArraysRequire',
    function oneOfArraysRequire(deps: [string, string], error?: string) {
        const [path1, path2] = deps;

        const t = locales.getFixedT('en', localeName);
        const message: string = error || t('oneOfArraysRequireError', { path1, path2 });

        return this.test('oneOfArraysRequire', message, function oneOfArraysRequireTest(values) {
            const { parent, path } = this as OneOfArraysRequireContextType;

            const array1Exists = Boolean(yup.array().compact().cast(parent[path1])?.length);
            const array2Exists = Boolean(yup.array().compact().cast(parent[path2])?.length);

            return !array1Exists && !array2Exists ? new yup.ValidationError(message, values, `${path}.0`) : true;
        });
    },
);

export { yup };
