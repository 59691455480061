import { gql } from '@humans-sdk/libs/apollo/client';

export const SizeFragment = gql`
    fragment Size on Size {
        columns
        rows
    }
`;

export const WidgetTypeFragment = gql`
    fragment WidgetType on WidgetType {
        id
        icon
        meta
        name
        namespace
        sizes {
            ...Size
        }
        version
    }
    ${SizeFragment}
`;

export const WidgetFragment = gql`
    fragment Widget on Widget {
        id
        state
        type {
            ...WidgetType
        }
    }
    ${WidgetTypeFragment}
`;

const WorkspaceFragment = gql`
    fragment Workspace on Workspace {
        id
        name
        publicLink
        grid {
            areas {
                position {
                    rowStart
                    rowEnd
                    colStart
                    colEnd
                }
                widget {
                    ...Widget
                }
            }
            size {
                ...Size
            }
        }
    }
    ${WidgetFragment}
    ${SizeFragment}
`;

export const WorkspacesFragment = gql`
    fragment Workspaces on Workspaces {
        all {
            active {
                ...Workspace
            }
            inactive {
                ...Workspace
            }
        }
    }
    ${WorkspaceFragment}
`;

export const workspacesAndWidgetsQuery = gql`
    query WorkspacesAndWidgets {
        me {
            widgets {
                all {
                    available {
                        ... on Widget {
                            ...Widget
                        }
                        ... on WidgetType {
                            ...WidgetType
                        }
                    }
                    presented {
                        ...Widget
                    }
                }
            }
            workspaces {
                ...Workspaces
            }
        }
    }
    ${WidgetFragment}
    ${WidgetTypeFragment}
    ${WorkspacesFragment}
`;

export const updateWorkspaceQuery = gql`
    query updateWorkspace($areas: [WorkspaceAreaInput!], $workspaceID: ID!) {
        me {
            workspaces {
                update(input: { areas: $areas, workspaceID: $workspaceID })
            }
        }
    }
`;

export const updatedWidgetsQuery = gql`
    query WorkspacesAndWidgets {
        me {
            id
            widgets {
                all {
                    presented {
                        ...Widget
                    }
                }
            }
        }
    }
    ${WidgetFragment}
    ${WidgetTypeFragment}
`;
