import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Text } from '@humans-sdk/core/components';
import { useScreenSize } from '@humans-sdk/core/hooks';
import { CloseIcon } from '@humans-sdk/core/icons';
import * as MuiIcons from '@humans-sdk/core/icons';
import { useTranslation } from '@humans-sdk/core/utils/locales';

import { COUNT_OF_WIDGETS_MOBILE, COUNT_OF_WIDGETS_TABLET, ENTERPRISE_LOCALE_NAME } from '@constants';
import { useWorkspaceContext } from '@containers/workspace';
import { WorkspaceStateType } from '@typings';
import { clsx } from '@utils';

import s from './settingWidgets.module.scss';

interface SettingWidgetsProps {
    onClose: () => void;
}

const SettingWidgets = ({ onClose }: SettingWidgetsProps): ReactElement => {
    const { available, presented, updateGrid, grid } = useWorkspaceContext();
    const { isMobile } = useScreenSize();
    const widgetsCount = useMemo(() => (isMobile ? COUNT_OF_WIDGETS_MOBILE : COUNT_OF_WIDGETS_TABLET), [isMobile]);
    const defaultArray = useMemo(
        () =>
            grid?.filter(Boolean).length
                ? grid
                      ?.filter(Boolean)
                      ?.map((elem) => (elem?.props as { id: string })?.id)
                      .slice(0, widgetsCount)
                : [],
        [grid, widgetsCount],
    );
    const [activeWidgets, setActiveWidgets] = useState<Array<string>>(defaultArray);
    const allCards = useMemo(
        () =>
            presented
                .sort(
                    (a, b) =>
                        (defaultArray.indexOf(a.id) + 1 || defaultArray.length) - (defaultArray.indexOf(b.id) + 1 || defaultArray.length),
                )
                .concat(available),
        [defaultArray, presented, available],
    );
    const { t } = useTranslation(ENTERPRISE_LOCALE_NAME);

    useEffect(() => {
        setActiveWidgets(defaultArray);
    }, [defaultArray]);

    const handleClickCard = useCallback((id: string) => {
        setActiveWidgets((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]));
    }, []);

    const onClickButton = () => {
        if (activeWidgets?.length) {
            const activeCards = allCards.filter((item) => activeWidgets.includes(item?.id));

            const newCells = activeCards.map(({ component, namespace, widgetId, title, id }, origin) => ({
                component,
                namespace,
                widgetId,
                title,
                id,
                params: {
                    origin,
                    size: '1x1',
                },
            })) as WorkspaceStateType;

            updateGrid(newCells);
            onClose();
            return;
        }

        onClose();
        updateGrid([null, null, null, null]);
    };

    const cards = useMemo(() => {
        return allCards.map(({ icon, id, title }) => {
            const Icon = MuiIcons?.[icon as 'AccountBalanceWalletIcon'];
            return (
                <Button
                    className={clsx(s.card, activeWidgets.length >= widgetsCount && !activeWidgets.includes(id) && s.disabled)}
                    key={id}
                    onClick={() => handleClickCard(id)}
                >
                    <div className={clsx(s.counterBlock, activeWidgets.includes(id) && s.counterBlockActive)}>
                        {activeWidgets.indexOf(id) + 1 || undefined}
                    </div>
                    {Icon && <Icon color="inherit" fontSize="inherit" className={s.icon} />}
                    <Text variant="body2" isResponsive>
                        {title}
                    </Text>
                </Button>
            );
        });
    }, [activeWidgets, allCards, handleClickCard, widgetsCount]);

    return (
        <div className={s.root}>
            <div className={s.contentWrapper}>
                <CloseIcon className={s.closeIcon} onClick={onClose} />
                <div className={s.content}>
                    <Text variant={isMobile ? 'body1' : 'h4'} isResponsive>
                        {t('settingUpWidgets')}
                    </Text>
                    <div className={s.blockCards}>{cards}</div>
                </div>
                <Button className={clsx(s.button)} onClick={onClickButton} variant="contained" color="primary" size="large" type="submit">
                    <Text variant={isMobile ? 'body1' : 'h4'} isResponsive>
                        {' '}
                        {t('saveChanges')}
                    </Text>
                </Button>
            </div>
        </div>
    );
};

export default SettingWidgets;
