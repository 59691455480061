import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { DialogRoutes } from '@humans-sdk/constants/auth';
import { Button, IconButton, SpinnerOverlay, Text, Tooltip } from '@humans-sdk/core/components';
import { useScreenSize } from '@humans-sdk/core/hooks';
import { ExpandMoreIcon } from '@humans-sdk/core/icons';
import { useTranslation } from '@humans-sdk/core/utils/locales';
import { useLocation } from '@humans-sdk/libs/react-router-dom';
import { AuthStatuses } from '@humans-sdk/typings';

import { useAuth } from '@api';
import { ENTERPRISE_LOCALE_NAME, PINNED } from '@constants';
import Content from '@containers/content';
import ContentMobile from '@containers/contentMobile';
import Header from '@containers/header';
import LayoutProvider from '@containers/layoutProvider';
import Library from '@containers/library';
import SettingWidgets from '@containers/settingWidgets';
import { WorkspaceProvider } from '@containers/workspace';
import { ContentType } from '@typings';
import { clsx } from '@utils';
import { useLocationMatch } from '@utils/hooks';

import selectWidgets from '../../assets/authdirections/select_widgets.svg';

import s from './Layout.module.scss';

interface Props {
    children?: ReactNode;
    withLibrary?: boolean;
    type?: ContentType;
}

const Layout = ({ children, type = ContentType.components, withLibrary = false }: Props): ReactElement | null => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCompact, setIsCompact] = useState(localStorage.getItem('layoutIsCompact') === 'true');
    const [isOpenSettings, setIsOpenSettings] = useState(false);
    const { status } = useAuth();
    const location = useLocation();
    const { pathname } = location;
    const { isDesktop } = useScreenSize();
    const { match } = useLocationMatch();
    const { t } = useTranslation(ENTERPRISE_LOCALE_NAME);
    const isNewRegistration = useMemo(
        () => pathname === DialogRoutes.RESET_MASTER_PIN || pathname === DialogRoutes.REGISTRATION,
        [pathname],
    );
    const isPendingAuth = useMemo(() => status === AuthStatuses.PENDING_AUTH, [status]);
    const activeWorkspaceId = match?.params.workspaceId || '';

    const handleExpanded = useCallback(() => {
        setIsCompact((prev) => {
            localStorage.setItem('layoutIsCompact', String(!prev));
            return !prev;
        });
    }, []);

    const setLoading = useCallback((flag: boolean) => {
        setIsLoading(flag);
    }, []);

    const onCloseSettings = () => {
        setIsOpenSettings(false);
    };

    const onOpenSettings = () => {
        setIsOpenSettings(true);
    };

    useEffect(() => {
        if (activeWorkspaceId) {
            localStorage.setItem(PINNED, JSON.stringify({ [activeWorkspaceId]: [] }));
        }
    }, [activeWorkspaceId]);

    useEffect(() => {
        if (isDesktop) {
            onCloseSettings();
        }
    }, [isDesktop]);

    return isPendingAuth || isNewRegistration ? null : (
        <div className={s.root}>
            <LayoutProvider setLoading={setLoading}>
                <Header />

                {!isLoading && (
                    <div className={s.main}>
                        <WorkspaceProvider>
                            {withLibrary && isDesktop && !isOpenSettings && (
                                <div className={clsx(s.menu, isCompact && s.menuCompact)}>
                                    <div className={s.menuHeader}>
                                        {!isCompact && (
                                            <Text className={s.title} variant="h3">
                                                {t('widgets')}
                                            </Text>
                                        )}
                                        <Tooltip title={isCompact ? t('expand') : t('collapse')} placement="right">
                                            <IconButton
                                                className={clsx(s.expand, isCompact && s.expandRotate)}
                                                size="small"
                                                aria-label="toogle"
                                                color="primary"
                                                onClick={handleExpanded}
                                            >
                                                <ExpandMoreIcon color="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Library className={s.library} isCompact={isCompact} />
                                </div>
                            )}

                            {isDesktop ? (
                                !isOpenSettings && (
                                    <Content type={type} isCompactMenu={isCompact}>
                                        {children}
                                    </Content>
                                )
                            ) : (
                                <ContentMobile type={type} isCompactMenu={isCompact}>
                                    {children}
                                </ContentMobile>
                            )}
                            {!isDesktop && isOpenSettings && <SettingWidgets onClose={onCloseSettings} />}
                            {!isDesktop && !isOpenSettings && (
                                <Button className={s.button} onClick={onOpenSettings} variant="contained" color="primary">
                                    <img className={s.selectWidgetsImg} alt="logo" src={selectWidgets} />
                                </Button>
                            )}
                        </WorkspaceProvider>
                    </div>
                )}
            </LayoutProvider>
            <SpinnerOverlay isLoading={isLoading} />
        </div>
    );
};

export default Layout;
