import { ReactElement, useMemo, useState } from 'react';

import { Radio, RadioGroup, Text } from '@humans-sdk/core/components';
import { useScreenSize } from '@humans-sdk/core/hooks';
import { ExpandMoreIcon } from '@humans-sdk/core/icons';
import { UserAvatar } from '@humans-sdk/core/kits/avatar';
import { ClickAwayListener } from '@humans-sdk/libs/mui/material';

import { useApolloClient, useSession } from '@api';
import { workspacesAndWidgetsQuery } from '@api/queries';
import { Button, Form, IconButton, Link } from '@components';
import { DEFAULT_AVATAR_ID, RouteNames } from '@constants';
import { EnterpriseGraphQLSchema } from '@typings';
import { clsx } from '@utils';

import s from './Header.module.scss';

interface State {
    isOpen: boolean;
}

const Header = (): ReactElement => {
    const { user } = useSession<EnterpriseGraphQLSchema.Profile>();
    const client = useApolloClient();
    const { isDesktop, isMobile } = useScreenSize();

    const me = client.readQuery<Pick<EnterpriseGraphQLSchema.Query, 'me'>>({
        query: workspacesAndWidgetsQuery,
    })?.me;

    const activeAvatar = useMemo(() => user?.avatars?.active, [user?.avatars?.active]);
    const workspaces = useMemo(() => {
        const all: EnterpriseGraphQLSchema.Workspace[] = [];

        if (me?.workspaces.all.active) {
            all.push(me.workspaces.all.active);
        }

        if (me?.workspaces.all.inactive) {
            all.push(...me.workspaces.all.inactive);
        }

        return all;
    }, [me?.workspaces.all.active, me?.workspaces.all.inactive]);

    const initialState: State = {
        isOpen: false,
    };

    const [state, setState] = useState<State>(initialState);
    const { isOpen } = state;

    const handleToggle = (toggle = !isOpen): void => {
        setState({
            isOpen: toggle,
        });
    };

    const classNamesRoot = useMemo(() => clsx(s.root, isOpen && !isDesktop && s.open), [isDesktop, isOpen]);

    const firstName: string = user?.personalData?.firstName || 'Johnny';
    const lastName: string = user?.personalData?.lastName || 'Mnemonic';

    const getNameWorkspace = (name: string) => (name === 'Unnamed Workspace' ? `${firstName} ${lastName}` : name);

    const items = workspaces.map((workspace, index) => {
        const { id, name: nameWorkspace } = workspace;
        const name = getNameWorkspace(nameWorkspace);
        const params = { avatarId: activeAvatar?.id || DEFAULT_AVATAR_ID, workspaceId: id };
        const key = `${id}${String(activeAvatar?.id || DEFAULT_AVATAR_ID)}`;

        return (
            <Link
                key={key}
                className={clsx(s.link, index === 0 && s.active)}
                onClick={(): void => handleToggle()}
                to={RouteNames.workspaces}
                params={params}
            >
                {!isDesktop ? (
                    <Radio labelClassesProps={{ root: s.radioRoot }} value={name} label={name} checked={index === 0} />
                ) : (
                    <Button>{name}</Button>
                )}
            </Link>
        );
    });

    const onClose = () => {
        handleToggle(false);
    };

    const header = (
        <div className={s.wrapper}>
            <div className={s.avatarBlock}>
                <Link className={clsx(s.link, s.linkHeader)} to="profile">
                    {user && <UserAvatar size={6} profile={user} className={s.avatar} classes={{ img: s.img }} />}
                    <div className={s.info}>
                        <Text className={s.text} variant={isMobile ? 'body2' : 'body1'} isResponsive>
                            {activeAvatar?.name}
                        </Text>
                        <Text className={s.text} variant="caption" color="secondary">
                            {firstName}
                        </Text>
                    </div>
                </Link>
            </div>
            <div className={s.blockSelect}>
                <Button color="primary" size="small" className={s.buttonList} onClick={(): void => handleToggle()}>
                    <Text className={s.title} variant={isMobile ? 'body2' : 'body1'} isResponsive>
                        {getNameWorkspace(workspaces[0]?.name || '')}
                    </Text>
                    <IconButton className={isOpen ? s.expandRotate : ''} color="primary" size="small">
                        <ExpandMoreIcon color="inherit" />
                    </IconButton>
                </Button>
            </div>
            <div className={clsx(!isDesktop ? s.list : s.listDesktop, !isOpen && s.buttonList)}>
                {!isDesktop ? (
                    <Form className={s.formRadio}>
                        <RadioGroup classes={{ root: s.radioGroup }} name="tableName">
                            {items}
                        </RadioGroup>
                    </Form>
                ) : (
                    items
                )}
            </div>
        </div>
    );

    return (
        <div className={classNamesRoot}>{!isDesktop ? <ClickAwayListener onClickAway={onClose}>{header}</ClickAwayListener> : header}</div>
    );
};

export default Header;
