import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const ArrowDownIcon = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} width="17" height="8" viewBox="0 0 17 8" {...props}>
            <path d="M15 1.42857L8.5 7L2 1.42857" stroke="#8994AA" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </SvgIcon>
    ),
);

export default ArrowDownIcon;
