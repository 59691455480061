import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Text } from '@humans-sdk/core/components';
import { useScreenSize } from '@humans-sdk/core/hooks';
import { clsx } from '@humans-sdk/core/utils';

import { EmptyWorkspace } from '@components';
import { COUNT_OF_WIDGETS_MOBILE, COUNT_OF_WIDGETS_TABLET } from '@constants';
import { useWorkspaceContext } from '@containers/workspace';
import { ContentProps, ContentType, WorkspaceGridElementType } from '@typings';

import s from './ContentMobile.module.scss';

const ContentMobile = ({ children, type = ContentType.components }: ContentProps): ReactElement => {
    const { grid } = useWorkspaceContext();
    const { isMobile } = useScreenSize();
    const containerRef = useRef<HTMLDivElement>(null);
    const refsArray = useRef<(HTMLDivElement | null)[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const widgetsCount = isMobile ? COUNT_OF_WIDGETS_MOBILE : COUNT_OF_WIDGETS_TABLET;

    const mobileGrid = grid.filter((item) => item).slice(0, widgetsCount);

    if (refsArray.current.length !== widgetsCount) {
        refsArray.current = Array(widgetsCount)
            .fill(null)
            .map(() => null);
    }

    const smoothScrollToElement = useCallback((container: HTMLElement, target: HTMLElement): Promise<void> => {
        return new Promise((resolve) => {
            const containerRect = container.getBoundingClientRect();
            const targetRect = target.getBoundingClientRect();

            const targetPosition = targetRect.top - containerRect.top + container.scrollTop;

            container.scrollTo({
                top: targetPosition,
                behavior: 'smooth',
            });

            const checkIfDone = () => {
                const currentScroll = container.scrollTop;
                if (Math.abs(currentScroll - targetPosition) < 1) {
                    resolve();
                } else {
                    requestAnimationFrame(checkIfDone);
                }
            };

            checkIfDone();
        });
    }, []);

    const handleClick = useCallback(
        (index: number) => () => {
            setIsScrolling(true);

            const target = refsArray.current[index];
            const container = containerRef.current;

            if (target && container) {
                setActiveIndex(index);
                void smoothScrollToElement(container, target).then(() => {
                    setIsScrolling(false);
                });
            } else {
                setIsScrolling(false);
            }
        },
        [smoothScrollToElement],
    );

    useEffect(() => {
        const options = {
            root: containerRef.current,
            rootMargin: '0px 0px -100% 0px',
            threshold: 0,
        };

        const observer = new IntersectionObserver((entries) => {
            if (isScrolling) return;
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = refsArray.current.findIndex((ref) => ref && ref === entry.target);
                    if (index !== -1) {
                        setActiveIndex(index);
                    }
                }
            });
        }, options);

        refsArray.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            observer.disconnect();
        };
    }, [mobileGrid, isScrolling]);

    const content: ReactNode = useMemo(() => {
        if (type === ContentType.components) {
            return children;
        }

        const getTitle = (element: WorkspaceGridElementType) => (element?.props as { 'data-title': string })['data-title'] || element?.key;

        return (
            <>
                {!!mobileGrid.length && (
                    <div className={s.widgetsHeader}>
                        {mobileGrid.map((item, index) => (
                            <Text
                                key={item?.key}
                                variant="body2"
                                isResponsive
                                onClick={handleClick(index)}
                                className={clsx(s.widgetTab, index === activeIndex && s.widgetTabActive)}
                            >
                                <span className={s.widgetTabText}>{getTitle(item)}</span>
                            </Text>
                        ))}
                    </div>
                )}

                {mobileGrid.length ? (
                    <div className={s.widgets} ref={containerRef}>
                        {mobileGrid.map((child, index) => (
                            <div
                                key={child?.key}
                                ref={(el) => {
                                    refsArray.current[index] = el;
                                }}
                            >
                                {child}
                            </div>
                        ))}
                    </div>
                ) : (
                    <EmptyWorkspace />
                )}
            </>
        );
    }, [type, mobileGrid, children, activeIndex, handleClick]);

    return <div className={s.root}>{content}</div>;
};

export default ContentMobile;
