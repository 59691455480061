import { FC } from 'react';

import locales from '@humans-sdk/core/utils/locales';

import { ENTERPRISE_LOCALE_NAME, theme } from '@constants';
import { ErrorBoundry, I18nextProvider, Router, SnackbarProvider, ThemeProvider } from '@containers';

import en from './locales/en-US.json';
import ru from './locales/ru-RU.json';

import s from './App.module.scss';

locales.addResourceBundle('en', ENTERPRISE_LOCALE_NAME, en);
locales.addResourceBundle('ru', ENTERPRISE_LOCALE_NAME, ru);

const App: FC = () => {
    return (
        <I18nextProvider i18n={locales}>
            <ThemeProvider theme={theme}>
                <ErrorBoundry>
                    <SnackbarProvider>
                        <div className={s.appWrapper}>
                            <Router />
                        </div>
                    </SnackbarProvider>
                </ErrorBoundry>
            </ThemeProvider>
        </I18nextProvider>
    );
};

export default App;
