import { DynamicImport } from '@typings';

const AttachmentsPage: DynamicImport = () =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'AttachmentsPage' */
        './attachmentsPage'
    );

export default {
    module: AttachmentsPage,
};
