import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@components';
import { SvgIconProps } from '@typings';

const NoAccessIcon = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} width="166" height="185" viewBox="0 0 166 185" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M165.309 63.5203H106.409V33.6012H91.6839V100.995H74.3151V33.6012H59.5908V63.5203H0.69104V78.2445H59.5908V185H74.3151V114.586H91.6839V185H106.409V78.2445H165.309V63.5203Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.9055 22.4615C89.5108 22.4615 94.2331 17.5559 94.2331 11.3266C94.2331 5.09642 89.1357 0 82.9055 0C76.6762 0 71.7669 5.09642 71.7669 11.3266C71.7669 17.5559 76.4854 22.4615 82.9055 22.4615Z"
                fill="black"
            />
        </SvgIcon>
    ),
);

export default NoAccessIcon;
