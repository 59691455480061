import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';

import { DialogRoutes } from '@humans-sdk/constants/auth';
import { SnackbarActionButton } from '@humans-sdk/core/components';
import { useWebsocket } from '@humans-sdk/core/kits';
import { useStore } from '@humans-sdk/enterprise/stores';
import { SnackbarKey, useSnackbar } from '@humans-sdk/libs/notistack';
import { generatePath, useLocation, useNavigate } from '@humans-sdk/libs/react-router-dom';
import { WebsocketContextType } from '@humans-sdk/typings';

import { useApolloClient, useSessionManager } from '@api';
import { workspacesAndWidgetsQuery } from '@api/queries';
import { NameSpaces, PINNED, RouteNames, WS_DISCONNECTION_SNACKBAR_BUTTON_TEXT, WS_DISCONNECTION_SNACKBAR_TEXT } from '@constants';
import { defaultWorkspaceCells, useWorkspaceContext } from '@containers/workspace';
import { routes } from '@routes';
import { EnterpriseGraphQLSchema, MessageMethod, WorkspaceMessage, WorkspaceMessageAction, WorkspaceRouteProps } from '@typings';
import { parseQS } from '@utils';

interface Props {
    children: ReactNode;
    setLoading: (flag: boolean) => void;
}

const LayoutProvider = ({ children, setLoading }: Props): ReactElement => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const location = useLocation();
    const manager = useSessionManager();
    const { messageStore } = useStore<WorkspaceMessage>({ namespace: NameSpaces.WORKSPACE });
    const { updateGrid } = useWorkspaceContext();

    // show popup on websocket connection error
    const { reconnect, RxStompClient } = useWebsocket() || ({} as WebsocketContextType);
    const [wsError, setWsError] = useState<Event | null>(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar() || {};
    const [wsConnectionErrorSnackbarKey, setWsConnectionErrorSnackbarKey] = useState<string>();

    useEffect(() => {
        combineLatest([RxStompClient?.webSocketErrors$, RxStompClient?.connectionState$])?.subscribe(([error, state]) => {
            const connectionStateOpen = 1;
            if (state !== connectionStateOpen && error) {
                setWsError(error);
            } else {
                setWsError(null);
            }
        });
    }, [RxStompClient?.webSocketErrors$, RxStompClient?.connectionState$]);

    useEffect(() => {
        if (!wsError) {
            if (wsConnectionErrorSnackbarKey) {
                closeSnackbar(wsConnectionErrorSnackbarKey);
            }
            return;
        }
        const action = (key: SnackbarKey) => {
            setWsConnectionErrorSnackbarKey(key.toString());
            return (
                <SnackbarActionButton
                    onClick={() => {
                        void reconnect().catch((e) => {
                            console.info('connect e', e);
                        });
                        closeSnackbar(key);
                    }}
                >
                    {WS_DISCONNECTION_SNACKBAR_BUTTON_TEXT}
                </SnackbarActionButton>
            );
        };
        enqueueSnackbar(WS_DISCONNECTION_SNACKBAR_TEXT, { persist: true, variant: 'error', preventDuplicate: true, action });
    }, [wsError, closeSnackbar, enqueueSnackbar, reconnect, wsConnectionErrorSnackbarKey]);

    const getRedirect = useCallback(
        (id: string | undefined) => {
            if (id) {
                // Redirect to correct URL
                const params: WorkspaceRouteProps = {
                    workspaceId: id,
                };

                const href = generatePath(routes[RouteNames.workspaces].path, params);
                navigate(href, { replace: true });
            }
        },
        [navigate],
    );

    const openClientWidget = (searchInput: string, workspace: NameSpaces, newData: Pick<EnterpriseGraphQLSchema.Query, 'me'>) => {
        const active =
            newData.me?.workspaces.all.active.name.toLocaleLowerCase() === workspace?.toLocaleLowerCase()
                ? [newData.me?.workspaces.all.active]
                : [];
        const inactive =
            newData.me?.workspaces.all.inactive?.filter(({ name }) => name.toLocaleLowerCase() === workspace?.toLocaleLowerCase()) || [];

        const needWorkspace = [...active, ...inactive];

        if (needWorkspace.length) {
            getRedirect(needWorkspace[0]?.id);
            updateGrid(defaultWorkspaceCells());

            setTimeout(() => {
                messageStore.send(
                    [
                        {
                            data: { action: WorkspaceMessageAction.ADD_WIDGET, params: { namespace: NameSpaces.CC_PROFILE } },
                            receiver: NameSpaces.WORKSPACE,
                        },
                    ],
                    MessageMethod.store,
                );

                setTimeout(() => {
                    messageStore.send(
                        [
                            {
                                data: { params: { namespace: NameSpaces.CC_PROFILE, searchInput } },
                                receiver: NameSpaces.CC_PROFILE,
                            },
                        ],
                        MessageMethod.store,
                    );
                }, 4000);
            }, 1000);
        }
    };

    useEffect(() => {
        const getWorkspaces = async () => {
            try {
                setLoading(true);

                const deviceToken = await manager.getDeviceToken();
                const isNewRegistration =
                    location.pathname === DialogRoutes.RESET_MASTER_PIN || location.pathname === DialogRoutes.REGISTRATION;

                const params = parseQS(location.search);
                const { searchInput, workspace } = params as {
                    searchInput?: string;
                    workspace?: NameSpaces;
                };

                if (!deviceToken || isNewRegistration) {
                    if (searchInput) {
                        navigate(`/${location.search}`, { replace: true });
                    }

                    return;
                }

                const { data: newData } = (await client.query({
                    query: workspacesAndWidgetsQuery,
                    fetchPolicy: 'network-only',
                })) as { data: Pick<EnterpriseGraphQLSchema.Query, 'me'> };

                if (searchInput) {
                    localStorage.removeItem(PINNED);

                    openClientWidget(searchInput, workspace as NameSpaces, newData);
                } else {
                    const newWorkspaceId = newData.me?.workspaces.all.active.id;
                    getRedirect(newWorkspaceId);
                }
            } finally {
                setLoading(false);
            }
        };

        void getWorkspaces();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <>{children}</>;
};

export default LayoutProvider;
