import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Linkedin = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 24 24" {...props}>
            <path d="M7.9425 4.93614C7.9425 6.00567 7.06375 6.87228 5.97917 6.87228C4.89458 6.87228 4.01583 6.00567 4.01583 4.93614C4.01583 3.86739 4.89458 3 5.97917 3C7.06375 3 7.9425 3.86739 7.9425 4.93614ZM7.95833 8.4212H4V20.8125H7.95833V8.4212ZM14.2774 8.4212H10.3444V20.8125H14.2782V14.3078C14.2782 10.6911 19.0512 10.3953 19.0512 14.3078V20.8125H23V12.9665C23 6.86376 15.9368 7.08603 14.2774 10.0901V8.4212Z" />
        </SvgIcon>
    ),
);

export default Linkedin;
