import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Languages = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 16 16" {...props}>
            <path
                d="M7.99971 0.982422C3.95363 0.982422 0.662109 3.84354 0.662109 7.36002C0.662109 9.33634 1.73027 11.2144 3.52835 12.4176C3.48803 12.7485 3.32163 13.5767 2.63651 14.5488C2.57283 14.6394 2.56451 14.7578 2.61539 14.8563C2.66627 14.9549 2.76771 15.017 2.87843 15.0176C2.88483 15.0176 2.89059 15.0176 2.89731 15.0176C4.64067 15.0173 5.81411 13.9402 6.18787 13.5421C6.77763 13.6717 7.38595 13.7376 7.99971 13.7376C12.0458 13.7376 15.3373 10.8768 15.3373 7.36002C15.3373 3.84322 12.0458 0.982422 7.99971 0.982422ZM4.47971 5.12002H11.5197C11.6964 5.12002 11.8397 5.26306 11.8397 5.44002C11.8397 5.61698 11.6964 5.76002 11.5197 5.76002H4.47971C4.30307 5.76002 4.15971 5.61698 4.15971 5.44002C4.15971 5.26306 4.30307 5.12002 4.47971 5.12002ZM3.19971 7.68002C3.02307 7.68002 2.87971 7.53698 2.87971 7.36002C2.87971 7.18306 3.02307 7.04002 3.19971 7.04002H8.63971C8.81635 7.04002 8.95971 7.18306 8.95971 7.36002C8.95971 7.53698 8.81635 7.68002 8.63971 7.68002H3.19971ZM11.5197 9.60002H4.47971C4.30307 9.60002 4.15971 9.45698 4.15971 9.28002C4.15971 9.10306 4.30307 8.96002 4.47971 8.96002H11.5197C11.6964 8.96002 11.8397 9.10306 11.8397 9.28002C11.8397 9.45698 11.6964 9.60002 11.5197 9.60002ZM12.7997 7.68002H10.5597C10.3831 7.68002 10.2397 7.53698 10.2397 7.36002C10.2397 7.18306 10.3831 7.04002 10.5597 7.04002H12.7997C12.9764 7.04002 13.1197 7.18306 13.1197 7.36002C13.1197 7.53698 12.9764 7.68002 12.7997 7.68002Z"
                fill="black"
            />
        </SvgIcon>
    ),
);

export default Languages;
