import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Location = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 10 14" {...props}>
            <path
                d="M4.99967 0.333008C2.42234 0.333008 0.333008 2.42234 0.333008 4.99967C0.333008 8.33301 4.99967 13.6663 4.99967 13.6663C4.99967 13.6663 9.66634 8.33301 9.66634 4.99967C9.66634 2.42234 7.57701 0.333008 4.99967 0.333008ZM4.99967 6.66634C4.07901 6.66634 3.33301 5.92034 3.33301 4.99967C3.33301 4.07901 4.07901 3.33301 4.99967 3.33301C5.92034 3.33301 6.66634 4.07901 6.66634 4.99967C6.66634 5.92034 5.92034 6.66634 4.99967 6.66634Z"
                fill="black"
            />
        </SvgIcon>
    ),
);

export default Location;
