import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Slack = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 24 24" {...props}>
            <path d="M9.866 4c-.884 0-1.6.718-1.6 1.603 0 .884.716 1.602 1.6 1.603h1.601V5.604c0-.885-.716-1.603-1.6-1.604zm0 4.275H5.6c-.884 0-1.6.719-1.6 1.603-.001.884.715 1.602 1.6 1.604h4.266c.885-.001 1.601-.719 1.6-1.603.001-.885-.715-1.603-1.6-1.604zM20 9.878c0-.884-.716-1.602-1.6-1.603-.884 0-1.6.719-1.6 1.603v1.604h1.6c.884-.001 1.6-.719 1.6-1.604zm-4.267 0V5.603c.001-.884-.715-1.602-1.6-1.603-.884 0-1.6.718-1.6 1.603v4.275c0 .884.716 1.602 1.6 1.604.885-.001 1.601-.719 1.6-1.604zM14.133 20.032c.885 0 1.601-.718 1.6-1.603.001-.884-.715-1.602-1.6-1.603h-1.6v1.603c0 .884.716 1.602 1.6 1.603zm0-4.276H18.4c.884 0 1.6-.718 1.6-1.603.002-.884-.715-1.602-1.6-1.603h-4.266c-.885 0-1.601.718-1.6 1.603-.001.885.715 1.603 1.6 1.603zM4 14.153c0 .885.716 1.602 1.6 1.603.885 0 1.601-.718 1.6-1.603V12.55H5.6c-.884.001-1.6.719-1.6 1.603zm4.267 0v4.275c-.001.885.715 1.603 1.6 1.604.884 0 1.6-.718 1.6-1.603v-4.274c.001-.885-.715-1.603-1.6-1.604-.885 0-1.6.718-1.6 1.602z" />
        </SvgIcon>
    ),
);

export default Slack;
