import { gql } from '@humans-sdk/libs/apollo/client';

export const SetActiveWorkspaceOutputFragment = gql`
    fragment SetActiveWorkspaceOutput on SetActiveWorkspaceOutput {
        void
    }
`;

export const mutationSetActiveWorkspace = gql`
    mutation SetActiveWorkspace($workspaceID: ID!) {
        setActiveWorkspace(input: { workspaceID: $workspaceID }) {
            ...SetActiveWorkspaceOutput
        }
    }
    ${SetActiveWorkspaceOutputFragment}
`;
