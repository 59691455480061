import { PropsWithChildren, useMemo } from 'react';
import { useDrag } from 'react-dnd';

import { DropTypes, NameSpaces } from '@constants';
import { clsx, dragCollect } from '@utils';

import s from './DragItem.module.scss';

interface Props {
    className?: string;
    namespace: NameSpaces;
    type: DropTypes;
}

interface DragObject {
    namespace: NameSpaces;
}

interface CollectedProps {
    isDragging: boolean;
}

const DragItem = (props: PropsWithChildren<Props>) => {
    const { namespace, type, className, children } = props;

    const [{ isDragging }, dragRef, previewRef] = useDrag<DragObject, undefined, CollectedProps>({
        type,
        item: { namespace },
        collect: dragCollect,
    });

    const classNames = useMemo(() => clsx(s.root, className, isDragging && s.onDrag), [isDragging, className]);

    return (
        <div ref={previewRef} className={classNames}>
            <div ref={dragRef} className={s.handle}>
                {children}
            </div>
        </div>
    );
};

export default DragItem;
