import { useCallback, useEffect, useState } from 'react';

import { isEqualObjects } from '@humans-sdk/core/utils/helpers';

import { useApolloClient, useQuery } from '@api';
import { updateWorkspaceQuery, workspacesAndWidgetsQuery } from '@api/queries';
import { defaultWorkspaceContext } from '@containers/workspace';
import { EnterpriseGraphQLSchema, LibraryApproved, WidgetApproved, WorkspaceContextInterface, WorkspaceStateType } from '@typings';
import { areasWorkspaceAdapter, getRegistryWidget, workspaceAreasAdapter } from '@utils';

export default function useLocationContextCreation(): WorkspaceContextInterface {
    const client = useApolloClient();
    const response = useQuery<Pick<EnterpriseGraphQLSchema.Query, 'me'>>(workspacesAndWidgetsQuery, { fetchPolicy: 'cache-only' });

    const {
        widgets: {
            all: { available: userAvailable, presented: userPresented },
        },
        workspaces: {
            all: {
                active: {
                    id: workspaceId,
                    name: workspaceName,
                    grid: { areas },
                },
            },
        },
    } = response?.data?.me ?? {
        widgets: {
            all: {
                available: [],
                presented: [],
            } as EnterpriseGraphQLSchema.AvailableAndPresentedWidgets,
        },
        workspaces: {
            all: {
                active: {
                    id: '',
                    name: '',
                    grid: {},
                } as Pick<EnterpriseGraphQLSchema.Workspace, 'grid' | 'id' | 'name'>,
            },
        },
    };

    const {
        cells: defaultCells,
        library: defaultRegistry,
        available: defaultAvailableWidgets,
        presented: defaultPresentedWidgets,
    } = defaultWorkspaceContext;

    // Numeration of cell goes by mirrored letter "N"
    const [cells, setCells] = useState<WorkspaceStateType>(defaultCells);

    const [library, setRegistry] = useState<LibraryApproved>(defaultRegistry);
    const [available, setAvailableWidgets] = useState<WidgetApproved[]>(defaultAvailableWidgets);
    const [presented, setPresentedWidgets] = useState<WidgetApproved[]>(defaultPresentedWidgets);

    const updateGrid = useCallback(
        (grid: WorkspaceStateType) => {
            setCells(grid);

            if (workspaceId) {
                void client
                    .query<EnterpriseGraphQLSchema.Query, EnterpriseGraphQLSchema.UpdateWorkspaceInput>({
                        query: updateWorkspaceQuery,
                        variables: {
                            areas: workspaceAreasAdapter(grid),
                            workspaceID: workspaceId,
                        },
                        fetchPolicy: 'no-cache',
                    })
                    .then(() => {
                        void client.query({
                            query: workspacesAndWidgetsQuery,
                            fetchPolicy: 'network-only',
                        });
                    });
            }
        },
        [workspaceId, client],
    );

    useEffect(() => {
        const initialLibrary: LibraryApproved = {};

        const initialPresented =
            userPresented?.reduce<WidgetApproved[]>((list, detachedWidget) => {
                const widget = getRegistryWidget(detachedWidget);

                if (widget) {
                    initialLibrary[widget.namespace] = widget;
                    list.push(widget);
                }

                return list;
            }, []) || [];

        const initialAvailable =
            userAvailable?.reduce<WidgetApproved[]>((list, detachedWidget) => {
                const widget = getRegistryWidget(detachedWidget);

                if (widget && !initialLibrary[widget.namespace]) {
                    initialLibrary[widget.namespace] = widget;
                    list.push(widget);
                }

                return list;
            }, []) || [];

        const initialCells = areasWorkspaceAdapter(areas, initialLibrary);

        setCells((prevState) => (isEqualObjects(initialCells, prevState) ? prevState : initialCells));
        setRegistry((prevState) => (isEqualObjects(initialLibrary, prevState) ? prevState : initialLibrary));
        setAvailableWidgets((prevState) => (isEqualObjects(initialAvailable, prevState) ? prevState : initialAvailable));
        setPresentedWidgets((prevState) => (isEqualObjects(initialPresented, prevState) ? prevState : initialPresented));
    }, [areas, userAvailable, userPresented]);

    return { cells, updateGrid, library, available, presented, workspaceId, workspaceName };
}
