import { forwardRef, ReactNode, Ref, useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { mergeRefs } from '@humans-sdk/core/utils/helpers';

import { DropTypes } from '@constants';
import { WidgetDropObject, WidgetDroppedProps } from '@typings';
import { clsx, dropCollect } from '@utils';

import s from './DropPlace.module.scss';

interface Props {
    index?: number;
    onDrop: (item: WidgetDropObject) => void;
    accept: DropTypes | DropTypes[];
    className?: string;
    isExistSelectGrid?: boolean;
    children?: ReactNode;
}

const DropPlace = (props: Props, ref: Ref<HTMLElement>) => {
    const { accept, isExistSelectGrid, onDrop, className, children, index } = props;

    const [{ canDrop, isOver }, drop] = useDrop<WidgetDropObject, void, WidgetDroppedProps>({
        accept,
        drop: onDrop,
        collect: dropCollect,
    });

    const classNames = useMemo(
        () => clsx(s.root, className, isExistSelectGrid && !canDrop && s.available, canDrop && s.active, isOver && s.hovered),
        [isExistSelectGrid, className, canDrop, isOver],
    );

    return (
        <>
            <div ref={index === 1 ? mergeRefs([drop, ref]) : drop} className={classNames}>
                {children}
            </div>
        </>
    );
};

export default forwardRef(DropPlace);
