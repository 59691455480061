import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Phone = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 24 24" {...props}>
            <path d="M19.22 15.25L16.7 14.96C16.09 14.89 15.49 15.1 15.06 15.53L13.21 17.38C11.791 16.66 10.504 15.721 9.39201 14.608C8.27901 13.496 7.34001 12.209 6.62001 10.79L8.47001 8.94C8.90001 8.51 9.11001 7.91 9.04001 7.3L8.75001 4.78C8.63001 3.77 7.78001 3.01 6.76001 3.01H5.03001C3.90001 3.01 2.96001 3.95 3.03001 5.08C3.29501 9.349 5.13401 13.188 7.97301 16.027C10.812 18.866 14.651 20.705 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C20.99 16.22 20.23 15.37 19.22 15.25Z" />
        </SvgIcon>
    ),
);

export default Phone;
