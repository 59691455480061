import { ReactElement, ReactNode, useCallback, useMemo } from 'react';

import { SnackbarActionButton } from '@humans-sdk/core/components';
import { CloseIcon, DeleteForeverOutlinedIcon } from '@humans-sdk/core/icons';
import { SnackbarKey, useSnackbar } from '@humans-sdk/libs/notistack';
import { generatePath, useNavigate } from '@humans-sdk/libs/react-router-dom';

import { Button, WidgetCard } from '@components';
import { DropTypes, NameSpaces, PINNED, RouteNames, WIDGET_PINNED } from '@constants';
import { defaultWorkspaceCells, useWorkspaceContext } from '@containers/workspace';
import { routes } from '@routes';
import { LibraryCardsType, WidgetCardProps, WorkspaceRouteProps } from '@typings';
import { clsx } from '@utils';
import { useLocationMatch } from '@utils/hooks';

import s from './Library.module.scss';

interface Props {
    className?: string;
    isCompact?: boolean;
}

const Library = ({ className, isCompact = false }: Props): ReactElement => {
    const { handleDrop, updateGrid, available, presented, cells, workspaceId } = useWorkspaceContext();
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const pinnedStore = localStorage?.getItem(PINNED) || '';
    const pinnedDefault = useMemo(() => (pinnedStore ? JSON.parse(pinnedStore) : []) as Record<string, string[]>, [pinnedStore]);
    const pinned = useMemo(() => pinnedDefault[workspaceId] || [], [pinnedDefault, workspaceId]);

    const { match } = useLocationMatch();

    const getRedirect = useCallback(() => {
        const id = match?.params.workspaceId || '';
        if (id) {
            // Redirect to correct URL
            const params: WorkspaceRouteProps = {
                workspaceId: id,
            };

            const href = generatePath(routes[RouteNames.workspaces].path, params);
            navigate(href, { replace: true });
        }
    }, [navigate, match?.params?.workspaceId]);

    const action = useCallback(
        (key: SnackbarKey) => (
            <SnackbarActionButton
                onClick={() => {
                    closeSnackbar(key);
                }}
            >
                <CloseIcon />
            </SnackbarActionButton>
        ),
        [closeSnackbar],
    );

    const handleClick = useCallback(
        (namespace: NameSpaces) => {
            if (cells.every((widget) => pinned.includes(widget?.namespace || ''))) {
                enqueueSnackbar(WIDGET_PINNED, { variant: 'error', action });

                return;
            }

            handleDrop({ namespace, type: DropTypes.simple }, pinned.includes(cells[0]?.namespace || '') ? 1 : 0);
        },
        [action, cells, enqueueSnackbar, handleDrop, pinned],
    );

    const addCard = useCallback(
        (namespace: NameSpaces, title: string = '', icon: string = '', active: boolean = false): ReactElement => {
            const props: WidgetCardProps = {
                namespace,
                name: title || namespace,
                icon,
                onClick: () => handleClick(namespace),
            };

            if (!active) {
                props.canDrop = true;
            }

            return (
                <WidgetCard
                    key={namespace}
                    {...props}
                    data-testid={`library-card-test-${active ? 'presented' : 'available'}-${namespace}`}
                    isCompact={isCompact}
                />
            );
        },
        [handleClick, isCompact],
    );

    const cards = useMemo<LibraryCardsType>(() => {
        const selected: ReactNode[] = [];
        const listed: ReactNode[] = [];

        presented.forEach(({ namespace, title, icon }) => {
            const card: ReactElement | null = addCard(namespace, title, icon, true);
            selected.push(card);
        });

        available.forEach(({ namespace, title, icon }) => {
            const card: ReactElement | null = addCard(namespace, title, icon, false);
            listed.push(card);
        });

        return {
            selected,
            listed,
        };
    }, [addCard, available, presented]);

    const classNames = useMemo(() => clsx(s.root, className), [className]);
    const classNameActiveList = clsx(s.list, s.on);

    const { selected, listed } = cards;

    const handleClear = () => {
        if (pinned?.length) {
            const newCells = cells.map((item) => (pinned.includes(item?.namespace || '') ? item : null));

            updateGrid(newCells);

            enqueueSnackbar(WIDGET_PINNED, { variant: 'error', action });

            return;
        }

        updateGrid(defaultWorkspaceCells());
        getRedirect();
    };

    return (
        <div className={classNames}>
            <div className={classNameActiveList}>
                {selected}

                <Button
                    onClick={handleClear}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={clsx(s.clear, isCompact && s.clearIconCollapse)}
                    disabled={!selected.length}
                >
                    <DeleteForeverOutlinedIcon onClick={handleClear} color="inherit" fontSize="inherit" className={s.clearIcon} />
                    {!isCompact && <span className={s.clearTitle}>Очистить</span>}
                </Button>
            </div>
            <div className={s.list}>{listed}</div>
        </div>
    );
};

export default Library;
