import { NameSpaces } from '@humans-sdk/enterprise/constants';

import { GenWidgetProps, WorkspaceGridElementType } from '@typings';

import CachedWidget from './cachedWidget';

class WidgetsCache {
    private cachedWidgets = new Map<NameSpaces, CachedWidget>();

    getWidget(props: GenWidgetProps): WorkspaceGridElementType {
        let cachedWidget = this.cachedWidgets.get(props.namespace);
        if (cachedWidget) {
            cachedWidget.passProps(props);
        } else {
            cachedWidget = new CachedWidget(props);
            this.cachedWidgets.set(props.namespace, cachedWidget);
        }
        return cachedWidget.component;
    }

    clear(): void {
        this.cachedWidgets.clear();
    }
}
const widgetsCache = new WidgetsCache();

export default widgetsCache;
