import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Telegram = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 24 24" {...props}>
            <path d="M3.22615 10.968L15.1395 6.21897C16.3155 5.72428 20.3036 4.14125 20.3036 4.14125C20.3036 4.14125 22.1443 3.44868 21.9909 5.13064C21.9398 5.82322 21.5307 8.24722 21.1217 10.8691L19.8435 18.6358C19.8435 18.6358 19.7412 19.7736 18.872 19.9715C18.0028 20.1694 16.5711 19.2789 16.3155 19.0811C16.111 18.9326 12.4807 16.7065 11.1513 15.6182C10.7934 15.3214 10.3844 14.7277 11.2025 14.0352C13.0431 12.4027 15.2417 10.3744 16.5711 9.08821C17.1847 8.49457 17.7982 7.10942 15.2417 8.79139L8.03238 13.491C8.03238 13.491 7.2143 13.9857 5.6804 13.5405C4.14649 13.0952 2.35694 12.5016 2.35694 12.5016C2.35694 12.5016 1.12981 11.7596 3.22615 10.968Z" />
        </SvgIcon>
    ),
);

export default Telegram;
