import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Twitter = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 24 24" {...props}>
            <path d="M21.634 4.03096C20.819 4.41596 19.432 5.13796 18.735 5.27596C18.708 5.28296 18.686 5.29196 18.66 5.29896C17.847 4.49696 16.733 3.99996 15.5 3.99996C13.015 3.99996 11 6.01496 11 8.49996C11 8.63096 10.989 8.87196 11 8.99996C7.78202 8.99996 5.43202 7.32096 3.67302 5.16296C3.43802 4.87296 3.18802 5.02396 3.13602 5.22996C3.01902 5.69596 2.97902 6.47496 2.97902 7.03096C2.97902 8.43196 4.07402 9.80796 5.77902 10.661C5.46502 10.742 5.11902 10.8 4.75902 10.8C4.33502 10.8 3.84702 10.689 3.42002 10.465C3.26202 10.382 2.92102 10.405 3.02202 10.809C3.42702 12.428 5.27502 13.565 6.92602 13.896C6.55102 14.117 5.75102 14.072 5.38302 14.072C5.24702 14.072 4.77402 14.04 4.46802 14.002C4.18902 13.968 3.76002 14.04 4.11902 14.584C4.89002 15.751 6.63402 16.484 8.13502 16.512C6.75302 17.596 4.49302 17.992 2.32802 17.992C1.89002 17.982 1.91202 18.481 2.26502 18.666C3.86202 19.504 6.47802 20 8.34702 20C15.777 20 20 14.337 20 8.99896C20 8.91296 19.998 8.73296 19.995 8.55196C19.995 8.53396 20 8.51696 20 8.49896C20 8.47196 19.992 8.44596 19.992 8.41896C19.989 8.28296 19.986 8.15596 19.983 8.08996C20.572 7.66496 21.474 6.92696 21.93 6.36196C22.085 6.16996 21.96 5.93696 21.749 6.00996C21.206 6.19896 20.267 6.56496 19.679 6.63496C20.856 5.85596 21.438 5.17796 21.938 4.42496C22.109 4.16796 21.895 3.90696 21.634 4.03096Z" />
        </SvgIcon>
    ),
);

export default Twitter;
