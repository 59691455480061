/*
    WARNING: ENUM SORTING DEFINE PRIORITY IN ROUTE MATCHING
*/
export enum RouteNames {
    // login = 'login',
    home = 'home',
    profile = 'profile',
    scans = 'scans',
    attachments = 'attachments',
    workspaces = 'workspaces',
    defaultPage = 'defaultPage',
}

export default RouteNames;
