import { createContext, CSSProperties } from 'react';

import { WorkspaceGridType, WorkspaceProviderInterface, WorkspaceStateType } from '@typings';

const gridTemplateAreas: CSSProperties['gridTemplateAreas'] = `
    "cell0 cell1"
    "cell2 cell3"
`;

export const defaultWorkspaceCells = (): WorkspaceStateType => [null, null, null, null];
export const defaultWorkspaceGrid = (): WorkspaceGridType => [null, null, null, null];

export const defaultWorkspaceContext: WorkspaceProviderInterface = {
    cells: defaultWorkspaceCells(),
    grid: defaultWorkspaceGrid(),
    gridTemplateAreas,
    handleDrop: () => {},
    updateGrid: () => {},
    library: {},
    available: [],
    presented: [],
    workspaceId: '',
    workspaceName: '',
};

export default createContext<WorkspaceProviderInterface>(defaultWorkspaceContext);
