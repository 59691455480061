import { DynamicImport } from '@typings';

const ScansPage: DynamicImport = () =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'ScansPage' */
        './scansPage'
    );

export default {
    module: ScansPage,
};
