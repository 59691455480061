import { DynamicImport } from '@typings';

const HomePage: DynamicImport = () =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'HomePage' */
        './homePage'
    );

export default {
    module: HomePage,
};
