import { WidgetSize } from '@typings';

/*
    2x2 = 1111                         ==> | x x |
                                           | x x |

    2x1 = 0101 | 1010                  ==> | x . |  | . x |
                                           | x . |  | . x |

    1x2 = 0011 | 1100                  ==> | x x |  | . . |
                                           | . . |  | x x |

    1x1 = 0001 | 0010 | 0100 | 1000    ==> | x . |  | . x |  | . . |   | . . |
                                           | . . |  | . . |  | x . |   | . x |
*/
const SizesBitMap: Record<WidgetSize, number[]> = {
    [WidgetSize.TWOxTWO]: [15], // ['1111']
    [WidgetSize.TWOxONE]: [5, 10], // ['0101', '1010'],
    [WidgetSize.ONExONE]: [1, 2, 4, 8], // ['0001', '0010', '0100', '1000'],
};

export default SizesBitMap;
