import { DragSourceMonitor, DropTargetMonitor } from 'react-dnd';

export const dropCollect = (monitor: DropTargetMonitor) => ({
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
});

export const dragCollect = (monitor: DragSourceMonitor) => ({
    isDragging: monitor.isDragging(),
});
