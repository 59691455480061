import { forwardRef, MouseEvent, ReactElement, ReactNode, Ref } from 'react';

import { generatePath, NavLink, useLocation, useMatch } from '@humans-sdk/libs/react-router-dom';

import { RouteNames } from '@constants';
import { routes } from '@routes';
import { RouteParamsProps } from '@typings';
import { clsx } from '@utils';

type Props = Partial<Pick<RouteParamsProps, 'params'>> & {
    activeClassName?: string;
    children?: ReactNode;
    className?: string;
    onClick?: (event: MouseEvent) => unknown;
    byRouter?: boolean;
    exact?: boolean;
    to: keyof typeof RouteNames;
    isActive?: <Params extends { [K in keyof Params]?: string }>(
        match: ReturnType<typeof useMatch> | null,
        location: ReturnType<typeof useLocation>,
    ) => boolean;
};

/**
 * Renders link element, but for page navigation usage.
 * i.e. processes passed "to" prop, and creates page url of it
 */

const Link = (props: Props, ref: Ref<HTMLAnchorElement>): ReactElement => {
    const { activeClassName, children, className, onClick, to, byRouter = true, isActive, params = {}, ...rest } = props;
    const routeMatch = useMatch(to);
    const location = useLocation();

    if (!byRouter) {
        return (
            <a className={clsx(className, routeMatch && activeClassName)} href={to} onClick={onClick}>
                {children}
            </a>
        );
    }

    const { path } = routes[to];
    const href = generatePath(path, params);

    const isActiveLink = isActive ? isActive(routeMatch, location) : routeMatch !== null;

    return (
        <NavLink ref={ref} className={clsx(className, isActiveLink && activeClassName)} onClick={onClick} to={href} {...rest}>
            {children}
        </NavLink>
    );
};

export default forwardRef(Link);
