import { FC, RefAttributes } from 'react';
import { useObservableState } from 'observable-hooks';
import { Observable } from 'rxjs';

import { WidgetChildProps, WidgetComponent } from '@typings';

interface CachedWidgetWrapperProps<P = {}, T = HTMLDivElement> {
    component: WidgetComponent;
    props$: Observable<WidgetChildProps & P & RefAttributes<T>>;
}

const CachedWidgetWrapper: FC<CachedWidgetWrapperProps> = ({ props$, component: CachedWidgetComponent }) => {
    const widgetProps = useObservableState(props$);
    return <CachedWidgetComponent {...widgetProps} />;
};

export default CachedWidgetWrapper;
