import { useDrop } from 'react-dnd';

import { useScreenSize } from '@humans-sdk/core/hooks';
import { useTranslation } from '@humans-sdk/core/utils/locales';

import { Text } from '@components';
import { DropTypes, ENTERPRISE_LOCALE_NAME } from '@constants';
import { EmptyWorkspaceIcon, WidgetSizeIcon } from '@icons';
import { WidgetDropObject, WidgetDroppedProps } from '@typings';
import { dropCollect } from '@utils';

import s from './EmptyWorkspace.module.scss';

const EmptyWorkspace = () => {
    const { t } = useTranslation(ENTERPRISE_LOCALE_NAME);
    const { isDesktop } = useScreenSize();

    const [{ canDrop }, drop] = useDrop<WidgetDropObject, void, WidgetDroppedProps>({
        accept: [DropTypes.simple, DropTypes.complex],
        collect: dropCollect,
    });

    if (canDrop) {
        return null;
    }

    return (
        <div className={s.root} ref={drop}>
            <WidgetSizeIcon
                filledSquares={[
                    [true, true],
                    [true, true],
                ]}
                color="action"
                classes={{ root: s.widgetIcon }}
            />
            <Text variant="body1" color="textPrimary" classes={{ root: s.workspaceText }}>
                {t(isDesktop ? 'emptyText' : 'emptyTextForMobile')}
            </Text>
            <EmptyWorkspaceIcon classes={{ root: s.workspaceIcon }} />
        </div>
    );
};

export default EmptyWorkspace;
