import { DynamicImport } from '@typings';

const NotFound: DynamicImport = () =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'NotFound' */
        './notFoundPage'
    );

export default {
    module: NotFound,
};
