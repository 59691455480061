import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Link = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 24 24" {...props}>
            <path d="M6 7C3.077 7 0.73944 9.50823 1.02344 12.4902C1.27144 15.0902 3.60285 17 6.21485 17H9C9.552 17 10 16.552 10 16C10 15.448 9.552 15 9 15H6.16992C4.54592 15 3.08986 13.7832 3.00586 12.1602C2.91486 10.4302 4.29 9 6 9H9C9.552 9 10 8.552 10 8C10 7.448 9.552 7 9 7H6ZM15 7C14.448 7 14 7.448 14 8C14 8.552 14.448 9 15 9H17.8301C19.4541 9 20.9101 10.2168 20.9941 11.8398C21.0851 13.5698 19.71 15 18 15H15C14.448 15 14 15.448 14 16C14 16.552 14.448 17 15 17H18C20.923 17 23.2606 14.4918 22.9766 11.5098C22.7286 8.90977 20.3972 7 17.7852 7H15ZM8 11C7.448 11 7 11.448 7 12C7 12.552 7.448 13 8 13H16C16.552 13 17 12.552 17 12C17 11.448 16.552 11 16 11H8Z" />
        </SvgIcon>
    ),
);

export default Link;
