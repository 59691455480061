import { Location, matchPath } from '@humans-sdk/libs/react-router-dom';

import { RouteNames } from '@constants';
import { routes } from '@routes';
import { AppRouteMapped, MatchRoute } from '@typings';

export const defaultRoute = routes[RouteNames.defaultPage];

export default function matchRoute(routesMap: AppRouteMapped[], location: Location): MatchRoute {
    let match = null;

    const matched = routesMap.find((route) => {
        match = matchPath(
            {
                path: route.path,
            },
            location.pathname,
        );

        return match && route.module;
    });

    return {
        match,
        route: matched || defaultRoute,
    };
}
