import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const Facebook = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} viewBox="0 0 24 24" {...props}>
            <path d="M16.403 9.00004H14V7.00004C14 5.96804 14.084 5.31804 15.563 5.31804H16.431C16.983 5.31804 17.431 4.87004 17.431 4.31804V3.06404C17.431 2.54104 17.03 2.09404 16.508 2.05904C15.904 2.01804 15.299 1.99904 14.693 2.00004C11.98 2.00004 10 3.65704 10 6.69904V9.00004H8C7.448 9.00004 7 9.44804 7 10V12C7 12.552 7.448 13 8 13L10 12.999V21C10 21.552 10.448 22 11 22H13C13.552 22 14 21.552 14 21V12.997L16.174 12.996C16.682 12.996 17.109 12.615 17.167 12.11L17.396 10.114C17.465 9.52104 17.001 9.00004 16.403 9.00004Z" />
        </SvgIcon>
    ),
);

export default Facebook;
