import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const LogoutIcon = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} width="32" height="32" viewBox="0 0 32 32" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9998 4H6.66649C5.19983 4 3.99982 5.2 3.99982 6.66667V25.3333C3.99982 26.8 5.19983 28 6.66649 28H15.9998C17.4798 28 18.6665 26.8 18.6665 25.3333V21.3333C18.6665 20.597 18.0695 20 17.3332 20C16.5968 20 15.9998 20.597 15.9998 21.3333V25.3333H6.66649V6.66667H15.9998V10.6667C15.9998 11.403 16.5968 12 17.3332 12C18.0695 12 18.6665 11.403 18.6665 10.6667V6.66667C18.6665 5.2 17.4798 4 15.9998 4ZM21.7256 21.7258C21.2066 21.2068 21.2058 20.3657 21.7238 19.8458L24.2265 17.3333H12.6665C11.9301 17.3333 11.3332 16.7364 11.3332 16C11.3332 15.2636 11.9301 14.6667 12.6665 14.6667H24.2265L21.7238 12.1542C21.2058 11.6343 21.2066 10.7932 21.7256 10.2742C22.2452 9.75459 23.0877 9.75459 23.6074 10.2742L28.6261 15.2929C29.0166 15.6834 29.0166 16.3166 28.6261 16.7071L23.6074 21.7258C23.0878 22.2454 22.2452 22.2454 21.7256 21.7258Z"
                fill="black"
            />
        </SvgIcon>
    ),
);

export default LogoutIcon;
