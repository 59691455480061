import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@components';
import { SvgIconProps } from '@typings';

const EmptyWorkspaceIcon = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} width="268" height="154" viewBox="0 0 268 154" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <rect x="15" y="10" width="238.462" height="124" rx="9.53846" fill="white" />
            </g>
            <rect x="19.769" y="14.7692" width="19.0769" height="114.462" rx="4.76923" fill="#F4F5F7" />
            <circle cx="29.3078" cy="24.3077" r="4.76923" fill="white" />
            <rect x="43.6152" y="14.7692" width="52.4615" height="19.0769" rx="4.76923" fill="#F4F5F7" />
            <rect x="43.6152" y="36.2308" width="52.4615" height="19.0769" rx="4.76923" fill="#F4F5F7" />
            <rect x="43.6152" y="79.1539" width="52.4615" height="19.0769" rx="4.76923" fill="#F4F5F7" />
            <rect
                x="101.442"
                y="15.3654"
                width="146.654"
                height="113.269"
                rx="4.17308"
                fill="#6CC0B1"
                fillOpacity="0.16"
                stroke="#6CC0B1"
                strokeWidth="1.19231"
            />
            <path
                d="M174.769 83.9231C181.354 83.9231 186.692 78.5849 186.692 72C186.692 65.415 181.354 60.0769 174.769 60.0769C168.184 60.0769 162.846 65.415 162.846 72C162.846 78.5849 168.184 83.9231 174.769 83.9231Z"
                stroke="#6BC0B2"
                strokeWidth="2.38462"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M174.77 67.2308V76.7692" stroke="#6BC0B2" strokeWidth="2.38462" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M170 72H179.538" stroke="#6BC0B2" strokeWidth="2.38462" strokeLinecap="round" strokeLinejoin="round" />
            <g filter="url(#filter1_d)">
                <rect
                    x="71.2065"
                    y="51.7308"
                    width="52.4615"
                    height="19.0769"
                    rx="4.76923"
                    transform="rotate(15 71.2065 51.7308)"
                    fill="#F4F5F7"
                />
                <rect
                    x="71.6281"
                    y="52.4609"
                    width="51.2692"
                    height="17.8846"
                    rx="4.17308"
                    transform="rotate(15 71.6281 52.4609)"
                    stroke="#505F79"
                    strokeOpacity="0.24"
                    strokeWidth="1.19231"
                />
            </g>
            <rect
                x="75.8496"
                y="60.0769"
                width="42.9231"
                height="4.76923"
                rx="2.38462"
                transform="rotate(15 75.8496 60.0769)"
                fill="white"
            />
            <g filter="url(#filter2_d)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M113.571 70.6262C114.152 70.4052 115.298 70.5405 115.601 71.2135C115.858 71.7871 116.08 72.7543 116.092 72.5482C116.121 72.0901 116.063 71.0993 116.258 70.5815C116.4 70.204 116.678 69.8489 117.088 69.7235C117.433 69.6168 117.839 69.5795 118.197 69.6553C118.576 69.7347 118.974 70.0116 119.123 70.2748C119.561 71.0483 119.568 72.6327 119.589 72.5482C119.666 72.2105 119.673 71.0223 119.931 70.5815C120.102 70.2897 120.533 70.029 120.763 69.9868C121.119 69.9222 121.557 69.9023 121.929 69.9768C122.231 70.0377 122.639 70.4039 122.749 70.5815C123.014 71.0086 123.163 72.2155 123.208 72.6401C123.227 72.8152 123.297 72.1521 123.562 71.7263C124.054 70.9329 125.794 70.7789 125.859 72.5197C125.891 73.3317 125.884 73.2944 125.884 73.8407C125.884 74.4814 125.869 74.8688 125.835 75.3332C125.799 75.8286 125.695 76.9522 125.543 77.4961C125.439 77.8698 125.094 78.7104 124.753 79.2145C124.753 79.2145 123.453 80.7665 123.312 81.4643C123.17 82.1633 123.217 82.1683 123.188 82.6624C123.16 83.1578 123.335 83.8085 123.335 83.8085C123.335 83.8085 122.365 83.9376 121.841 83.8507C121.368 83.7737 120.782 82.8077 120.631 82.5122C120.423 82.105 119.978 82.1832 119.805 82.4836C119.534 82.9592 118.947 83.8122 118.535 83.8656C117.725 83.9699 116.047 83.9028 114.734 83.8904C114.734 83.8904 114.958 82.6351 114.459 82.2043C114.09 81.8815 113.455 81.2309 113.075 80.8882L112.068 79.7446C111.725 79.2977 110.855 78.5912 110.563 77.28C110.306 76.1179 110.331 75.548 110.608 75.0824C110.889 74.6093 111.419 74.3511 111.642 74.3064C111.893 74.2542 112.479 74.2579 112.701 74.3833C112.971 74.5361 113.08 74.5808 113.291 74.8688C113.57 75.25 113.669 75.435 113.549 75.019C113.457 74.6937 113.159 74.2803 113.024 73.8147C112.892 73.3664 112.539 72.6438 112.564 71.92C112.574 71.6456 112.689 70.9627 113.571 70.6262Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M113.571 70.6262C114.152 70.4052 115.298 70.5405 115.601 71.2135C115.858 71.7871 116.08 72.7543 116.092 72.5482C116.121 72.0901 116.063 71.0993 116.258 70.5815C116.4 70.204 116.678 69.8489 117.088 69.7235C117.433 69.6168 117.839 69.5795 118.197 69.6553C118.576 69.7347 118.974 70.0116 119.123 70.2748C119.561 71.0483 119.568 72.6327 119.589 72.5482C119.666 72.2105 119.673 71.0223 119.931 70.5815C120.102 70.2897 120.533 70.029 120.763 69.9868C121.119 69.9222 121.557 69.9023 121.929 69.9768C122.231 70.0377 122.639 70.4039 122.749 70.5815C123.014 71.0086 123.163 72.2155 123.208 72.6401C123.227 72.8152 123.297 72.1521 123.562 71.7263C124.054 70.9329 125.794 70.7789 125.859 72.5197C125.891 73.3317 125.884 73.2944 125.884 73.8407C125.884 74.4814 125.869 74.8688 125.835 75.3332C125.799 75.8286 125.695 76.9522 125.543 77.4961C125.439 77.8698 125.094 78.7104 124.753 79.2145C124.753 79.2145 123.453 80.7665 123.312 81.4643C123.17 82.1633 123.217 82.1683 123.188 82.6624C123.16 83.1578 123.335 83.8085 123.335 83.8085C123.335 83.8085 122.365 83.9376 121.841 83.8507C121.368 83.7737 120.782 82.8077 120.631 82.5122C120.423 82.105 119.978 82.1832 119.805 82.4836C119.534 82.9592 118.947 83.8122 118.535 83.8656C117.725 83.9699 116.047 83.9028 114.734 83.8904C114.734 83.8904 114.958 82.6351 114.459 82.2043C114.09 81.8815 113.455 81.2309 113.075 80.8882L112.068 79.7446C111.725 79.2977 110.855 78.5912 110.563 77.28C110.306 76.1179 110.331 75.548 110.608 75.0824C110.889 74.6093 111.419 74.3511 111.642 74.3064C111.893 74.2542 112.479 74.2579 112.701 74.3833C112.971 74.5361 113.08 74.5808 113.291 74.8688C113.57 75.25 113.669 75.435 113.549 75.019C113.457 74.6937 113.159 74.2803 113.024 73.8147C112.892 73.3664 112.539 72.6438 112.564 71.92C112.574 71.6456 112.689 70.9627 113.571 70.6262Z"
                    stroke="black"
                    strokeWidth="0.894231"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M117.538 76.1731C117.538 75.8438 117.271 75.5769 116.942 75.5769C116.613 75.5769 116.346 75.8438 116.346 76.1731V79.75C116.346 80.0792 116.613 80.3461 116.942 80.3461C117.271 80.3461 117.538 80.0792 117.538 79.75V76.1731ZM119.326 75.5769C119.656 75.5769 119.923 75.8438 119.923 76.1731V79.75C119.923 80.0792 119.656 80.3461 119.326 80.3461C118.997 80.3461 118.73 80.0792 118.73 79.75V76.1731C118.73 75.8438 118.997 75.5769 119.326 75.5769ZM121.711 75.5769C122.04 75.5769 122.307 75.8438 122.307 76.1731V79.75C122.307 80.0792 122.04 80.3461 121.711 80.3461C121.382 80.3461 121.115 80.0792 121.115 79.75V76.1731C121.115 75.8438 121.382 75.5769 121.711 75.5769Z"
                fill="black"
            />
            <rect x="48.3848" y="21.9231" width="42.9231" height="4.76923" rx="2.38462" fill="white" />
            <rect x="48.3848" y="43.3846" width="42.9231" height="4.76923" rx="2.38462" fill="white" />
            <rect x="48.3848" y="86.3077" width="42.9231" height="4.76923" rx="2.38462" fill="white" />
            <defs>
                <filter
                    id="filter0_d"
                    x="0.692307"
                    y="0.461538"
                    width="267.077"
                    height="152.615"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="4.76923" />
                    <feGaussianBlur stdDeviation="7.15385" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.592157 0 0 0 0 0.627451 0 0 0 0 0.686275 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter
                    id="filter1_d"
                    x="62.5706"
                    y="51.6091"
                    width="63.0085"
                    height="39.402"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="3.57692" />
                    <feGaussianBlur stdDeviation="2.38462" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter
                    id="filter2_d"
                    x="107.552"
                    y="67.9759"
                    width="21.1635"
                    height="19.9712"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1.19231" />
                    <feGaussianBlur stdDeviation="1.19231" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.12549 0 0 0 0 0.239216 0 0 0 0.24 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </SvgIcon>
    ),
);

export default EmptyWorkspaceIcon;
