import { DynamicImport } from '@typings';

const ProfilePage: DynamicImport = () =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'ProfilePage' */
        './profilePage'
    );

export default {
    module: ProfilePage,
};
