import { RefAttributes } from 'react';

import { GenWidgetProps, WidgetChildProps } from '@typings';
import { widgetSizeAdapter } from '@utils';

function propsConvert(newProps: Omit<GenWidgetProps, 'Widget'>): WidgetChildProps & RefAttributes<HTMLDivElement> {
    const {
        size,
        origin,
        namespace,
        availableSizes,
        handleSizeChange,
        handleRemoveWidget,
        handlePinned,
        pinned = false,
        isDesktop,
    } = newProps;
    return {
        key: namespace,
        title: namespace,
        foldable: false,
        folded: false,
        availableSizes: isDesktop ? availableSizes : undefined,
        currentSize: isDesktop ? widgetSizeAdapter(size, origin) : undefined,
        changeSize: (targetSize) => handleSizeChange({ targetSize, size, origin, namespace }),
        removeWidget: () => handleRemoveWidget?.(namespace),
        handlePinned: () => handlePinned?.(namespace),
        pinnable: isDesktop,
        pinned: isDesktop ? pinned : undefined,
    };
}

export default propsConvert;
