import { CSSProperties, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { fromManagerToManagerLite, ManagerProvider, useSessionManager } from '@humans-sdk/core/api';
import { SnackbarActionButton } from '@humans-sdk/core/components';
import { useScreenSize } from '@humans-sdk/core/hooks';
import { CloseIcon } from '@humans-sdk/core/icons';
import { isEqualObjects } from '@humans-sdk/core/utils';
import { NameSpaces } from '@humans-sdk/enterprise/constants';
import { useStore } from '@humans-sdk/enterprise/stores';
import { ApolloProvider } from '@humans-sdk/libs/apollo/client';
import { SnackbarKey, useSnackbar } from '@humans-sdk/libs/notistack';
import { generatePath, useLocation, useNavigate } from '@humans-sdk/libs/react-router-dom';

import { DropTypes, PINNED, RouteNames, WIDGET_PINNED } from '@constants';
import { defaultWorkspaceContext, useWorkspaceContextCreation, WorkspaceContext } from '@containers/workspace';
import widgetsCache from '@containers/workspace/provider/widgetsRenderCache';
import { routes } from '@routes';
import {
    HandleRemoveWidgetType,
    HandleSizeChangeType,
    WidgetDropObject,
    WidgetSizeNames,
    WorkspaceCellType,
    WorkspaceGridType,
    WorkspaceMessage,
    WorkspaceMessageAction,
    WorkspaceProviderInterface,
    WorkspaceProviderProps,
    WorkspaceRouteProps,
} from '@typings';
import { clsx, getUniqWidgets, updateWorkspace, widgetSizeAdapter, widgetSizeNameAdapter, widgetSizesAdapter } from '@utils';
import { useLocationMatch } from '@utils/hooks';

import s from './WorkspaceProvider.module.scss';

const WorkspaceProvider = (props: PropsWithChildren<WorkspaceProviderProps>): ReactElement => {
    const manager = useSessionManager();
    const { match } = useLocationMatch();
    const { messageStore } = useStore<WorkspaceMessage>({ namespace: NameSpaces.WORKSPACE });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { queue } = messageStore;
    const activeWorkspaceId = match?.params.workspaceId || '';
    const pinnedStore = localStorage?.getItem(PINNED) || '';
    const pinnedDefault = useMemo(() => (pinnedStore ? JSON.parse(pinnedStore) : {}) as Record<string, string[]>, [pinnedStore]);
    const [pinned, setPinned] = useState<string[]>(pinnedDefault[activeWorkspaceId] || []);

    const navigate = useNavigate();
    const location = useLocation();

    // use passed data default, if exists
    const {
        children,
        available: passedAvailable,
        presented: passedPresented,
        library: passedLibrary,
        updateGrid: passedUpdateGrid,
        workspaceName: passedWorkspaceName,
    } = props;

    const {
        cells,
        updateGrid: contextUpdateGrid,
        library: contextLibrary,
        available: contextAvailable,
        presented: contextPresented,
        workspaceName: contextWorkspaceName,
    } = useWorkspaceContextCreation();

    const available = passedAvailable || contextAvailable;
    const presented = passedPresented || contextPresented;
    const library = useMemo(() => ({ ...(passedLibrary || contextLibrary) }), [passedLibrary, contextLibrary]);

    const updateGrid = passedUpdateGrid || contextUpdateGrid;
    const workspaceName = passedWorkspaceName || contextWorkspaceName;

    const { gridTemplateAreas: defaultGridTemplateAreas } = defaultWorkspaceContext;

    const [blinkWidgetNamespace, setBlinkWidgetNamespace] = useState<NameSpaces | null>(null);
    const [rnd, setRandom] = useState<number>(0);

    useEffect(() => {
        if (activeWorkspaceId) {
            setPinned(pinnedDefault[activeWorkspaceId] || []);
        }
    }, [activeWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const action = useCallback(
        (key: SnackbarKey) => (
            <SnackbarActionButton
                onClick={() => {
                    closeSnackbar(key);
                }}
            >
                <CloseIcon />
            </SnackbarActionButton>
        ),
        [closeSnackbar],
    );

    const enqueueSnackbarCall = useCallback(() => {
        enqueueSnackbar(WIDGET_PINNED, { variant: 'error', action });
    }, [action, enqueueSnackbar]);

    const handleDrop = useCallback(
        ({ namespace, targetSize }: WidgetDropObject, index?: number) => {
            if (getUniqWidgets(cells).includes(namespace) && !targetSize) {
                setBlinkWidgetNamespace(namespace);
                setRandom(Math.random());
            } else {
                setBlinkWidgetNamespace(null);
                updateWorkspace({
                    library,
                    namespace,
                    cells,
                    cellIndex: index,
                    targetSize,
                    onUpdate: updateGrid,
                    pinnedStore: pinned,
                    pinned: pinned?.includes(namespace) || false,
                    enqueueSnackbar: enqueueSnackbarCall,
                });
            }
        },
        [cells, library, updateGrid, pinned, enqueueSnackbarCall],
    );

    const handleMessageStoreQueueRead = useCallback(
        ({ message }: { message: WorkspaceMessage; sender: NameSpaces }) => {
            if (message.action === WorkspaceMessageAction.ADD_WIDGET) {
                handleDrop({ ...message?.params, type: DropTypes.simple } as WidgetDropObject, undefined);
            }
        },
        [handleDrop],
    );

    useEffect(() => {
        queue?.subscribe(handleMessageStoreQueueRead);
        return () => queue?.unsubscribe();
    }, [queue, handleMessageStoreQueueRead]);

    const handleSizeChange: HandleSizeChangeType = useCallback(
        ({ targetSize, size, origin, namespace }) => {
            const currentSize = widgetSizeAdapter(size, origin);

            let target = origin;

            if (targetSize === WidgetSizeNames.TWOxONE_LEFT) {
                target = 0;
            } else if (targetSize === WidgetSizeNames.TWOxONE_RIGHT) {
                target = 1;
            } else if (targetSize === WidgetSizeNames.ONExONE) {
                if (currentSize === WidgetSizeNames.TWOxONE_LEFT && origin === 2) {
                    target = 0;
                } else if (currentSize === WidgetSizeNames.TWOxONE_RIGHT && origin === 3) {
                    target = 1;
                }
            }
            handleDrop({ namespace, type: DropTypes.simple, targetSize: widgetSizeNameAdapter(targetSize) }, target);
        },
        [handleDrop],
    );

    const getRedirect = useCallback(
        (id: string) => {
            if (id) {
                // Redirect to correct URL
                const params: WorkspaceRouteProps = {
                    workspaceId: id,
                };

                const href = generatePath(routes[RouteNames.workspaces].path, params);
                navigate(href, { replace: true });
            }
        },
        [navigate],
    );

    const handleRemoveWidget: HandleRemoveWidgetType = useCallback(
        (namespace) => {
            if (pinned.includes(namespace)) {
                enqueueSnackbarCall();

                return;
            }

            const widget = library[namespace]!;
            const newCells = cells.map((item) => (item?.id !== widget.id ? item : null));

            updateGrid(newCells);

            const { search } = location;

            if (search.includes(namespace)) {
                getRedirect(activeWorkspaceId);
            }
        },
        [activeWorkspaceId, cells, enqueueSnackbarCall, getRedirect, library, location, pinned, updateGrid],
    );

    const handlePinned = useCallback(
        (namespace: NameSpaces) => {
            setPinned((prev) => {
                if (prev.includes(namespace)) {
                    const newPinned = [...prev].filter((item) => item !== namespace);
                    localStorage.setItem(PINNED, JSON.stringify({ ...pinnedDefault, [activeWorkspaceId]: newPinned }));

                    return newPinned;
                }

                const newPinned = [...prev, namespace];
                localStorage.setItem(PINNED, JSON.stringify({ ...pinnedDefault, [activeWorkspaceId]: newPinned }));

                return newPinned;
            });
        },
        [activeWorkspaceId, pinnedDefault],
    );

    const [gridState, setGridState] = useState({
        grid: defaultWorkspaceContext.grid,
        gridTemplateAreas: defaultWorkspaceContext.gridTemplateAreas,
    });

    const { isDesktop } = useScreenSize();

    useEffect(() => {
        const addedWidgets = new Set<NameSpaces>();
        let gridAreas = defaultGridTemplateAreas;

        const gridCells: WorkspaceGridType = cells.map((cell: WorkspaceCellType, i: number) => {
            if (cell === null || !cell.component) {
                return null;
            }

            const { component: Widget, params, namespace, title, id } = cell;
            const { origin, size } = params;
            const cellName = `cell${i}`;

            const styleWidget: CSSProperties = {
                gridArea: namespace,
                order: origin,
            };

            gridAreas = gridAreas?.replace(cellName, namespace);
            if (!addedWidgets.has(namespace)) {
                const { settings } = library[namespace]!;

                const { sizes = [] } = settings;
                const availableSizes = widgetSizesAdapter(sizes);

                addedWidgets.add(namespace);

                const isBlinkWidgetNamespace = blinkWidgetNamespace === namespace;

                const keyBorder = `${namespace}-${size}-${String(i)}-${isBlinkWidgetNamespace && String(rnd)}`;

                const WidgetGenerate = widgetsCache.getWidget({
                    Widget,
                    namespace,
                    size,
                    origin,
                    availableSizes,
                    handleSizeChange,
                    handleRemoveWidget,
                    handlePinned,
                    pinned: pinned?.includes(namespace) || false,
                    isDesktop,
                });

                return (
                    <div className={s.widget} style={styleWidget} key={namespace} id={id} data-title={title}>
                        <ManagerProvider
                            key={namespace}
                            manager={fromManagerToManagerLite(manager, {
                                dbName: workspaceName,
                                storeName: namespace,
                                apolloLinkOptions: {
                                    headers: {
                                        'X-Humans-Widget-Namespace': namespace,
                                        'X-Humans-Workspace-Name': workspaceName,
                                    },
                                },
                            })}
                        >
                            {({ apolloGqlClient }) => (
                                <ApolloProvider client={apolloGqlClient} key={namespace}>
                                    <div
                                        style={styleWidget}
                                        className={clsx(s.border, isBlinkWidgetNamespace && s.blink)}
                                        key={keyBorder}
                                    />
                                    {WidgetGenerate}
                                </ApolloProvider>
                            )}
                        </ManagerProvider>
                    </div>
                );
            }

            return null;
        });

        setGridState((prevGridState) => {
            if (!isEqualObjects(prevGridState.grid, gridCells) || !isEqualObjects(prevGridState.gridTemplateAreas, gridAreas)) {
                return { grid: gridCells, gridTemplateAreas: gridAreas };
            }
            return prevGridState;
        });
    }, [
        defaultGridTemplateAreas,
        cells,
        library,
        blinkWidgetNamespace,
        rnd,
        manager,
        workspaceName,
        handleSizeChange,
        handleRemoveWidget,
        handlePinned,
        pinned,
        isDesktop,
    ]);

    const value: WorkspaceProviderInterface = {
        cells,
        handleDrop,
        updateGrid,
        library,
        available,
        presented,
        workspaceId: activeWorkspaceId,
        workspaceName,
        ...gridState,
    };

    return (
        <WorkspaceContext.Provider value={value}>
            <DndProvider backend={HTML5Backend} key="singleDndProvider">
                {children}
            </DndProvider>
        </WorkspaceContext.Provider>
    );
};

export default WorkspaceProvider;
