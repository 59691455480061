import { forwardRef, ReactElement, Ref } from 'react';

import { SvgIcon } from '@humans-sdk/core/components';
import { SvgIconProps } from '@humans-sdk/typings';

const ArrowRightIcon = forwardRef(
    (props: SvgIconProps, ref: Ref<SVGSVGElement>): ReactElement => (
        <SvgIcon ref={ref} width="8" height="14" viewBox="0 0 8 14" fill="none" {...props}>
            <path d="M2.00042 12L6.28613 7L2.00042 2" stroke="#8994AA" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </SvgIcon>
    ),
);

export default ArrowRightIcon;
